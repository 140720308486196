/**
 * @format
 * @flow strict-local
 */

import React, { Node, useCallback, useMemo } from 'react';
import type { ViewStyle } from 'react-native';
import _ from 'lodash';
import styled from '@emotion/native';

import Radio from '@appComponents/Radio';
import List, { useGenericListControl } from '@appComponents/List';
import { Spacer } from '@appComponents/ScreenLayout';
import PassengerEdit from './PassengerEdit';
import type { PassengerItem } from './PassengerEdit';

type PassengerListProps = {
  passengers: Array<PassengerItem & { isPendingAdd?: boolean }>,
  onAdd?: PassengerItem => void,
  canAdd?: Boolean,
  onRemove: PassengerItem => void,
  onReplace: ({ prev: PassengerItem, next: PassengerItem }) => void,
  setLead?: PassengerItem => void,
  style?: ViewStyle,
  numColumns?: number,
  ownerId?: String,
  aircraft: Object,
};

const MAX_PASSENGERS = 20;

const PassengerList = ({
  passengers = [],
  onReplace,
  setLead,
  onAdd,
  canAdd,
  onRemove,
  style,
  numColumns,
  ownerId,
  owner,
  aircraft,
}: PassengerListProps): Node => {
  const { items, hasPendingAdd, addEntry, removeEntry, removePending } =
    useGenericListControl({ items: passengers, onRemove });

  const keyExtractor = useCallback((item, i) => item.name || i, []);

  const renderItem = useCallback(
    ({ item, index }: PassengerListItem) => (
      <>
        {setLead && (
          <LeadSelector
            value={item.name}
            checked={index === 0}
            onChange={() => !item.isPendingAdd && setLead(item)}
          />
        )}
        <PassengerEdit
          ownerId={ownerId}
          passenger={item}
          selectedPassengers={passengers}
          isLead={index === 0}
          onUpdate={({ isPendingAdd, ...passenger }: PassengerItem) => {
            if (isPendingAdd) {
              removePending();
              onAdd(passenger);
            } else {
              onReplace({ prev: item, next: passenger });
            }
          }}
          autoFocus={Boolean(onAdd)}
          owner={owner}
          aircraft={aircraft}
        />
      </>
    ),
    [
      aircraft,
      onAdd,
      onReplace,
      owner,
      ownerId,
      passengers,
      removePending,
      setLead,
    ],
  );

  return (
    <List
      items={items}
      onRemove={removeEntry}
      onAdd={addEntry}
      canAdd={canAdd}
      disableAdd={hasPendingAdd}
      addButtonLabel="Add Passenger"
      renderItem={renderItem}
      maxItems={MAX_PASSENGERS}
      style={style}
      numColumns={numColumns}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={ItemSeparator}
    />
  );
};

type PassengerListItem = { item: PassengerItem, index: number };

export const usePassengerListControl = ({ legs = [], changeLegs }) => {
  const addPassenger = useCallback(
    (passenger, legIndex = 0) =>
      changeLegs(
        legs.map((leg, i) => {
          // add the passenger to current and following legs
          if (
            i >= legIndex &&
            _.every(leg.passengers, p => p.name !== passenger.name)
          ) {
            return {
              ...leg,
              passengers: [...leg.passengers, passenger],
            };
          }

          return leg;
        }),
      ),
    [changeLegs, legs],
  );

  const removePassenger = useCallback(
    (passenger, legIndex = 0) =>
      changeLegs(
        legs.map((leg, i) => {
          // remove the passenger from current and following legs
          if (i >= legIndex) {
            return {
              ...leg,
              passengers: leg.passengers.filter(
                ({ name }) => name !== passenger.name,
              ),
            };
          }

          return leg;
        }),
      ),
    [changeLegs, legs],
  );

  const replacePassenger = useCallback(
    ({ prev, next }) =>
      changeLegs(
        legs.map(leg => ({
          ...leg,
          passengers: leg.passengers.map(p => {
            if (p.name === prev.name) {
              return next;
            }

            return p;
          }),
        })),
      ),
    [changeLegs, legs],
  );

  const allPassengers = useMemo(
    () => _.chain(legs).map('passengers').flatten().uniqBy('name').value(),
    [legs],
  );

  return {
    addPassenger,
    removePassenger,
    replacePassenger,
    allPassengers,
  };
};

const ItemSeparator = () => <Spacer size={0} />;

const LeadSelector: Radio = styled(Radio)({
  paddingHorizontal: 0,
  paddingVertical: 0,
});

export default PassengerList;
