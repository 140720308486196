/**
 * @format
 * @flow strict-local
 */

import React, { Fragment, Node, useCallback, useEffect } from 'react';
import styled from '@emotion/native';

import Text, { Title } from '@appComponents/Text';
import {
  ScreenLoader,
  SectionBody,
  SectionHeader,
  Spacer,
} from '@appComponents/ScreenLayout';
import { useAircraftData } from '@appUtils/aircraft';
import { useUserData } from '@appUtils/user';
import PassengerList, {
  usePassengerListControl,
} from '@webComponents/PassengerList';
import type { PassengerItem } from '@webComponents/PassengerEdit';

type PassengerProps = {
  legs: Leg[],
  changeLegs: (Leg[]) => void,
  owner: Object,
  ownerAdded: Boolean,
  setOwnerAdded: Function,
  ownerToRemove: Object,
  setOwnerToRemove: Function,
  aircraft: Object,
};

type Leg = {
  from: string,
  to: string,
  passengers: Array<PassengerItem>,
};

const Passengers = ({
  legs = [],
  changeLegs,
  owner,
  ownerAdded,
  setOwnerAdded,
  ownerToRemove,
  setOwnerToRemove,
  aircraft,
}: PassengerProps): Node => {
  const { addPassenger, removePassenger, replacePassenger, allPassengers } =
    usePassengerListControl({ legs, changeLegs });
  const ownerId = owner?.id;
  const [fullOwner, ownerLoading] = useUserData(ownerId);
  const [fullAircraft, aircraftLoading] = useAircraftData(aircraft?.path);

  useEffect(() => {
    if (owner?.id && !ownerAdded) {
      const newOwner = {
        name: `${owner.firstName} ${owner.lastName}`,
        id: owner.id,
        email: owner.email,
        phoneNumber: owner.phoneNumber,
      };
      setOwnerAdded(true);
      const prev = ownerToRemove
        ? { name: `${ownerToRemove.firstName} ${ownerToRemove.lastName}` }
        : undefined;
      const oldOwnerStillOnTrip = prev
        ? allPassengers.find(p => p.name === prev.name)
        : false;
      if (oldOwnerStillOnTrip) {
        replacePassenger({
          prev,
          next: newOwner,
        });
        setOwnerToRemove(undefined);
      } else {
        addPassenger(newOwner, 0);
      }
    }
  }, [
    addPassenger,
    allPassengers,
    owner,
    ownerAdded,
    ownerToRemove,
    replacePassenger,
    setOwnerAdded,
    setOwnerToRemove,
  ]);

  const shouldRenderLegLists =
    legs.length > 1 && legs.some(leg => leg.passengers.length > 0);

  const setLeadPassenger = useChangeLeadPassengerCallback(legs, changeLegs);

  const legRenderProps = {
    legs,
    addPassenger,
    removePassenger,
    replacePassenger,
    setLeadPassenger,
    ownerId,
  };

  if (ownerLoading || aircraftLoading) {
    return <ScreenLoader />;
  }

  return (
    <PassengersLayout>
      <SectionHeader>
        <Title color="dark" weight={500}>
          ALL
        </Title>
      </SectionHeader>

      <Section>
        <PassengerList
          ownerId={ownerId}
          numColumns={3}
          passengers={allPassengers}
          onAdd={addPassenger}
          onRemove={removePassenger}
          onReplace={replacePassenger}
          aircraft={fullAircraft}
          owner={fullOwner}
        />
      </Section>

      {shouldRenderLegLists && legs.length <= 3 && (
        <LegColumns {...legRenderProps} />
      )}

      {shouldRenderLegLists && legs.length > 3 && (
        <LegRows {...legRenderProps} />
      )}
    </PassengersLayout>
  );
};

const LegColumns = ({
  legs,
  addPassenger,
  removePassenger,
  replacePassenger,
  setLeadPassenger,
  ownerId,
}) => (
  <>
    <SectionHeader>
      <Title color="dark" weight={500}>
        PER LEG
      </Title>
    </SectionHeader>

    <Section minHeight={200}>
      {legs.map((leg, legIndex) => (
        <ListWrapper key={getLegLabel(leg, legIndex)}>
          <Text size="medium" color="dark" weight={500}>
            {getLegLabel(leg, legIndex)}
          </Text>
          <Spacer />
          <PassengerList
            ownerId={ownerId}
            passengers={leg.passengers}
            onAdd={passenger => addPassenger(passenger, legIndex)}
            onRemove={passenger => removePassenger(passenger, legIndex)}
            onReplace={replacePassenger}
            setLead={passenger => setLeadPassenger(leg, passenger)}
          />
        </ListWrapper>
      ))}
    </Section>
  </>
);

const LegRows = ({
  legs,
  addPassenger,
  removePassenger,
  updatePassenger,
  setLeadPassenger,
  ownerId,
}) =>
  legs.map((leg, legIndex) => (
    <Fragment key={leg.id}>
      <SectionHeader>
        <Title color="dark" weight={500}>
          {getLegLabel(leg, legIndex)}
        </Title>
      </SectionHeader>

      <Section>
        <PassengerList
          ownerId={ownerId}
          numColumns={3}
          passengers={leg.passengers}
          onAdd={passenger => addPassenger(passenger, legIndex)}
          onRemove={passenger => removePassenger(passenger, legIndex)}
          onReplace={updatePassenger}
          setLead={passenger => setLeadPassenger(leg, passenger)}
        />
      </Section>
    </Fragment>
  ));

const useChangeLeadPassengerCallback = (legs, changeLegs) =>
  useCallback(
    (leg, passenger) =>
      changeLegs(
        legs.map(existingLeg => {
          if (existingLeg.id === leg.id) {
            return {
              ...existingLeg,
              passengers: [
                passenger,
                ...existingLeg.passengers.filter(
                  p => p.name !== passenger.name,
                ),
              ],
            };
          }

          return existingLeg;
        }),
      ),
    [legs, changeLegs],
  );

const getLegLabel = ({ from, to }: Leg, index) =>
  `${index >= 0 ? `Leg ${index + 1} ` : ''}${from}>${to}`;

const PassengersLayout = styled.View(({ theme }) => ({
  flex: 1,
}));

const Section = styled(SectionBody)(({ theme, minHeight }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  minHeight,
  marginBottom: theme.layout.space(1),
  paddingHorizontal: theme.layout.space(0.5),
}));

const ListWrapper = styled.View(({ theme }) => ({
  paddingTop: theme.layout.space(1),
  paddingRight: theme.layout.space(1),
  maxWidth: '50%',
  minWidth: '33%',
  flex: 1,
}));

export default Passengers;
